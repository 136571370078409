/* src/components/Contact.css */
.contact-page {
  max-width: 800px;
  margin: 40px auto;
  padding: 20px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI",
    sans-serif;
}

.contact-title {
  font-size: 28px;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.contact-paragraph {
  font-size: 16px;
  color: #444;
  margin-bottom: 20px;
  line-height: 1.6;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.contact-input {
  width: 100%;
  height: 150px; /* Increased height for better usability */
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  resize: vertical; /* Allow vertical resizing */
}

.contact-input::placeholder {
  color: #555; /* Darker placeholder for readability */
}

.contact-button {
  background: #00c4b4; /* Teal for consistency with UploadDemo */
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s ease;
  width: 100%;
  align-self: flex-end; /* Align button to the right */
}

.contact-button:hover {
  background: #00a89a;
}

.contact-button:active {
  transform: scale(0.98);
}

/* Responsive */
@media (max-width: 600px) {
  .contact-page {
    margin: 20px 10px;
    padding: 15px;
  }

  .contact-title {
    font-size: 24px;
  }

  .contact-paragraph {
    font-size: 14px;
  }

  .contact-input {
    height: 120px;
    font-size: 14px;
  }

  .contact-button {
    padding: 10px 20px;
    font-size: 14px;
  }
}
