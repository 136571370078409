/* src/styles.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI",
    sans-serif;
  line-height: 1.6;
  background-color: #1a202c; /* Dark mode base */
  color: #ffffff; /* White text for dark mode */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  flex-grow: 1;
  margin: 50px auto;
  padding: 0.5rem; /* Mobile-first padding */
}

@media (min-width: 768px) {
  /* md breakpoint for desktop */
  main {
    padding: 1.5rem; /* Wider padding on desktop */
  }
}

/* Optional: Custom dark-mode class for flexibility (though Tailwind handles this) */
.dark-mode {
  background-color: #1a202c;
  color: #ffffff;
}

/* Custom animations (beyond Framer Motion, if needed) */
@keyframes slideIn {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.animate-slide-in {
  animation: slideIn 0.5s ease-out forwards;
}
